import React, { useState } from "react"
import styled from "styled-components"
import colors from "../styles/colors"
import richText from "../utils/richText"
import { Body } from "./typography"

const Wrapper = styled.div`
  color: ${colors.creamLight};
`
const Question: any = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.creamLight};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;

  ${Body} {
    font-weight: bold;
  }
`

export default function FAQQuestion({ question, answer }) {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper>
      <Question onClick={() => setOpen(!open)} open={open}>
        <Body>{question}</Body>
        {open ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.3335 8H12.6668"
              stroke="#F8F0D8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 3.33398V12.6673"
              stroke="#F8F0D8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.3335 8H12.6668"
              stroke="#F8F0D8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Question>
      {open && (
        <Body as="div" style={{ padding: "16px 0" }}>
          {richText(answer.raw)}
        </Body>
      )}
    </Wrapper>
  )
}
