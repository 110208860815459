import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import FAQQuestion from "../components/FAQQuestion"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Body, Header2, Header3 } from "../components/typography"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"

export const query = graphql`
  query FAQQuery {
    allContentfulFaqQuestion {
      nodes {
        order
        category
        answer {
          raw
        }
        id
        question
      }
    }
  }
`

const Wrapper = styled.div`
  max-width: 1440px;

  margin: 0 auto 64px;

  padding: 48px 24px;

  color: ${colors.creamLight};

  @media ${breakpoints.tablet} {
    display: grid;

    grid-template-columns: 20% 80%;
  }

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }
`

const Header = styled.div`
  color: ${colors.creamLight};

  margin: 0 auto;

  padding: 48px 24px;

  border-bottom: 1px solid ${colors.creamLight};

  text-align: center;
`

const LeftNav = styled.div`
  display: none;

  a {
    display: block;

    color: ${colors.creamLight};
    text-decoration: none;
  }

  @media ${breakpoints.tablet} {
    display: block;

    margin-top: 64px;
  }
`

const FAQWrapper = styled.div``

function slugify(string) {
  return string.replace(" ", "-").toLowerCase()
}

export function Head() {
  return <Seo title={"FAQ"} />
}

export default function FAQ({ data }) {
  const categories = data.allContentfulFaqQuestion.nodes.reduce((acc, v) => {
    const category = acc[v.category]

    if (category) {
      category?.push(v)
      acc[v.category] = category
    } else {
      acc[v.category] = [v]
    }

    return acc
  }, {})

  return (
    <Layout>
      <Header>
        <Header2>FAQ</Header2>
      </Header>
      <Wrapper>
        <LeftNav>
          {Object.keys(categories)
            .reverse()
            .map(category => (
              <Body as="a" href={`/faq#${slugify(category)}`}>
                {category}
              </Body>
            ))}
        </LeftNav>
        <FAQWrapper>
          {Object.keys(categories)
            .reverse()
            .map(category => (
              <>
                <Header3 id={slugify(category)} style={{ marginTop: 64 }}>
                  {category}
                </Header3>
                {categories[category]
                  .sort((a, b) => a.order - b.order)
                  .map(q => (
                    <FAQQuestion question={q.question} answer={q.answer} />
                  ))}
              </>
            ))}
        </FAQWrapper>
      </Wrapper>
    </Layout>
  )
}
